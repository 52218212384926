<template>
	<div id="landDelivery">
		<page-delivery-order :is-shop="0"></page-delivery-order>
	</div>
</template>
<script>
import pageDeliveryOrder from '@/components/layout/land/page-delivery-order.vue'
import { onBeforeRouteLeave } from 'vue-router'
import store from '@/store'
export default{
	name:"LandDeliveryOrder",
	components:{
		pageDeliveryOrder
	},
	setup(){
		onBeforeRouteLeave((to,from,next)=>{
			let type = to.path == '/land/delivery_detail' ? 1 : 2
			store.dispatch("setKeepPage",{name:from.name,type})
			next();
		})
	}
}
</script>

<style>
</style>