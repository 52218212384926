<template>
	<div class="delivery-order">
		<div class="el-content mb12">
			<div class="kd-pacel">搜索参数</div>
			<div class="mb12" style="display: flex;width: 100%;align-items: center;">
				<div style="width: 94px;">订单状态：</div>
				<a-radio-group v-model:value="search.status" button-style="solid" @change="getLandDeliveryOrderList(1,ldoState.limit)">
					<a-radio-button value="0">全部</a-radio-button>
					<a-radio-button value="1">待配送</a-radio-button>
					<a-radio-button value="2">已发货</a-radio-button>
					<a-radio-button value="3">已收货</a-radio-button>
					<a-radio-button value="4">已支付</a-radio-button>
					<a-radio-button value="5">未支付</a-radio-button>
					<a-radio-button value="6">已退款</a-radio-button>
					<a-radio-button value="7">已取消</a-radio-button>
				</a-radio-group>
			</div>
			<div class="mb12" style="display: flex;width: 100%;align-items: center;">
				<div style="width: 100px;">订单搜索：</div>
				 <a-input-group compact>
					<a-select v-model:value="search.type" style="width: 150px;">
						<a-select-option :value="1">配送订单编号</a-select-option>
						<a-select-option :value="2">收货人电话</a-select-option>
						<a-select-option :value="3">收货人姓名</a-select-option>
						<a-select-option :value="4">快递公司</a-select-option>
						<a-select-option :value="5">快递单号</a-select-option>
					</a-select>
					<a-input v-if="search.type == 1" style="width: 250px" v-model:value="search.order_number" />
					<a-input v-if="search.type == 2" style="width: 250px" v-model:value="search.phone" />
					<a-input v-if="search.type == 3" style="width: 250px" v-model:value="search.name" />
					<a-input v-if="search.type == 4" style="width: 250px" v-model:value="search.express_company" />
					<a-input v-if="search.type == 5" style="width: 250px" v-model:value="search.express_no" />
				</a-input-group>
			</div>
			<div class="mb12" style="display: flex;width: 100%;align-items: center;">
				<div style="width: 94px;">收货地址：</div>
				<a-input style="width: 400px" v-model:value="search.address" />
			</div>
			<a-space style="margin-left: 94px;">
				<a-button type="primary" @click="getLandDeliveryOrderList(1,ldoState.limit)">
                    <i class="ri-search-2-line ri-top"></i>搜索
                </a-button>
			</a-space>
		</div>
		<div class="el-content">
			<div v-if="ldoState.list.length > 0">
				<com-delivery-list :list="ldoState.list"
                                   @success="()=>getLandDeliveryOrderList(ldoState.page,ldoState.limit)">
                </com-delivery-list>
			</div>
			<div class="pager">
				<a-pagination
				show-size-changer
				:default-current="ldoState.page"
				:total="ldoState.count"
				@showSizeChange="(page,e)=>{ getLandDeliveryOrderList(ldoState.page, e); }"
				@change="e=>{getLandDeliveryOrderList(e, ldoState.limit);}"/>
			</div>
		</div>
	</div>
</template>

<script>
import comPrintFaceOrder from '@/components/miniapp/com-print-face-order.vue'
import comDeliveryList from '@/components/layout/land/com-delivery-list.vue'
import { onActivated, reactive } from 'vue'
import { useLandDeliveryOrder } from '@/models/land'
export default{
	name:'com-delivery-order',
	components:{
		comPrintFaceOrder,
		comDeliveryList
	},
	props:{
		isShop:{
			type:Number,
			default:0
		}
	},
	setup(){
	    let search = reactive({
            type:1,
            status:'0',
            order_number:'',
            name:'',
            phone:"",
            address:"",
            express_no:"",
            express_company:"",
        })
        let { ldoState ,getLandDeliveryOrderList } = useLandDeliveryOrder( search )
        getLandDeliveryOrderList(1,10)

		onActivated(()=>{
            getLandDeliveryOrderList(ldoState.page,ldoState.limit)
		})

		return {
			search,
            ldoState ,
            getLandDeliveryOrderList
		}
	}
}
</script>
<style>
</style>
